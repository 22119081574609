<template>
  <div class="text-gray-800 border-t-4 border-blue-500 modal">
    <div class="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        Create New Scene
      </h3>
    </div>

    <div class="px-8 py-4 modal-content bg-neutral-gray">
      <form
        :class="{ 'error' : form.errors.get('form'), 'error' : formFormHasErrors }"
        @submit.prevent="submit"
      >
        <div class="mb-6">
          <label
            for="name"
            class="block text-sm font-medium leading-5 text-gray-700"
          >
            Scene Name
          </label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input
              id="name"
              ref="nameinput"
              v-model="form.name"
              type="text"
              placeholder=""
              autofocus
              class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
            >
          </div>
        </div>
        <div class="flex items-center justify-end mt-6 btn-group">
          <template v-if="form.busy">
            <button
              class="btn"
              type="button"
            >
              <fa-icon
                icon="spinner"
                spin
                class="mr-1"
              /> Working
            </button>
          </template>
          <template v-else>
            <button
              class="btn"
              type="button"
              @click="$emit('close')"
            >
              Cancel
            </button>
            <button
              class="btn primary"
              type="button"
              @click.prevent="submit"
            >
              Create & Edit
            </button>
          </template>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {

  name: 'SequenceCreate',

  props: {
    parent: {
      type: String,
      required: true
    },
    project: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      form: new SparkForm({
        name: null
      }),
      formFormHasErrors: false
    }
  },

  computed: {

    readyToSubmit () {
      return this.form.name !== ''
    }
  },

  mounted () {
    this.$refs.nameinput.focus()
  },

  methods: {

    submit () {
      if (this.readyToSubmit && !this.form.busy) {
        this.formFormHasErrors = false
        this.form.startProcessing()
        this.$store.dispatch('assets/newAssetItem', {
          project: this.project,
          parent: this.parent,
          name: this.form.name,
          type: 'scene'
        })
          .then(result => {
            this.form.finishProcessing()
            this.$emit('close')
            alert.success('Scene created')
          })
          .catch(response => {
            alert.error('Failed to create sequence')
            this.formFormHasErrors = true
            this.form.setErrors(response.data)
            this.form.finishProcessing()
          })
      }
    }

  }
}
</script>
